import React from 'react'

import { LogoWrapepr } from './styles'

const Logo = ({ color, height = 32, onClick, logoVisible }) => (
  <LogoWrapepr onClick={onClick} logoVisible={logoVisible}>
    <svg height={height} viewBox="0 0 1577.01 901.19" style={{ color }}>
      <path
        fill="currentColor"
        d="M1574,544.8,1241.56,8.38C1236.21,1.5,1235,.53,1225.68,0c-8.93-.48-17.19,4.64-19.47,6.05C988,138.68,757.53,290.85,520.18,465.32,334.42,601.87,163.29,738.2,6.32,871.39c-10.17,8.69-5.15,15-4.6,17.42,1.65,7.18,34.06,22.08,57,3.16,105.47-95.84,221.7-194.73,349.14-294.24,115.14-89.89,227.49-171,335.36-244-71.91,64.32-131.1,116.8-172.24,153.18C472.35,594.1,429.12,631.45,363.07,692.48c-55.94,51.69-101,95.2-131,124.52a11,11,0,0,0-3.25,6.76,17.79,17.79,0,0,0,2.72,11.53c4.88,7.39,16.82,10.45,28.69,5.67a18.53,18.53,0,0,0,5.94-3.89q198.26-191.25,406.15-371.4,79.46-68.85,160.29-136.07c-34.68,43.81-64.39,78.21-85.09,101.5-54.13,60.89-76.78,87-107.28,130.35-5.09,7.23-18.67,22.5-38.44,51.83-14.08,20.89-29.27,43.19-26,58.13,1.83,6,5.19,21,18.76,27.13,19.54,8.81,43.95,11.72,65.8-2.53,18.38-13.13,35-28.52,47.48-22,2,1.06,8.14,4.75,11.58,15.74,2.06,7.92,7.54,13.21,12.16,15.13,25.49,10.55,62.94,7.75,101.92-21.39q-6.49,33.76-13,67.55c-3.05,16.37,2.22,27.62,9.36,30.17,3.32,1.19,15.92-7.28,22.85-21.67,5.76-12,20.84-43,53.84-65.09C938.62,673,974,678.27,983,676.33a9.47,9.47,0,0,0,2.68-17.9c-9.41-5-32.62-9.33-59.81-3.23-38.78,8.69-60.21,35.77-65.9,43.5,5.45-21,9.94-41.74,15.23-62.69a13.54,13.54,0,0,0-5.73-14.88,12.73,12.73,0,0,0-9.85-1.51c-6.58,1.45-9.83,5.12-11.43,6.41-27.37,30.48-51.5,49.83-70.76,35.3-12.08-9.11-16.13-35.41-4.26-62.85l46.53-97.08a12.2,12.2,0,0,0-3-15.5c-5.12-3.87-13-2.74-17.12,3-21.53,29.6-44.26,67.39-81.44,106.62-11.05,11.66-23.2,20.79-34.44,29.39-11,7-22.59,8.35-30.17.46-6.45-6.71-7.37-17.87-1.59-26.41,33.63-46.53,63.33-82,83.38-104.85,45.55-52,71.52-73.93,126.36-134.13,34.94-38.37,61.77-70.5,77.93-90.3a34,34,0,0,0,5.89-19.86,33.41,33.41,0,0,0-4.1-15l187.45-111.4L837.57,567.61A19.82,19.82,0,0,0,858.15,598l707.76-32.65c.58-.08,6.76-.93,9.71-6.52A12.78,12.78,0,0,0,1574,544.8Zm-653-10L1204.9,103l286.57,431.81Z"
      />
    </svg>
  </LogoWrapepr>
)

export default Logo
