import { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from 'gatsby'

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )

  function getWindowDimensions() {
    if (typeof window !== 'undefined') {
      const { innerWidth: width, innerHeight: height } = window
      return {
        width,
        height,
      }
    }
    // fallback for headless build process
    return {
      width: 1920,
      height: 1080,
    }
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}

export const useOuterClick = (onOuterClick, innerRef) => {
  useEffect(
    () => {
      // only add listener, if the element exists
      if (innerRef.current) {
        document.addEventListener('click', handleClick)
      }

      // unmount previous first in case inputs have changed
      return () => document.removeEventListener('click', handleClick)

      function handleClick(e) {
        innerRef.current &&
          !innerRef.current.contains(e.target) &&
          onOuterClick(e)
      }
    },
    [onOuterClick, innerRef] // invoke again, if inputs have changed
  )
}

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          socialLinks {
            name
            link
          }
          primaryNav {
            name
            link
          }
          secondaryNav {
            name
            link
          }
        }
      }
    }
  `)
  return site.siteMetadata
}

export const useOnScreen = (ref, rootMargin = '0px') => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting)
      },
      {
        rootMargin,
      }
    )
    if (ref.current) {
      observer.observe(ref.current)
    }
    return () => {
      observer.unobserve(ref.current)
    }
  }, []) // Empty array ensures that effect is only run on mount and unmount

  return isIntersecting
}
